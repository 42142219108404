.simulations,
.tablesInfos {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.simulationFilterAndSearch {
    display: flex;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
}

.simulationFilterAndSearch>input,
.simulationFilterAndSearch>div {
    width: 45%;
}

.simulationFilterAndSearch>div {
    display: flex;
    flex-direction: column;
    gap: 10px 0;
}

.simulations h1,
.tablesInfos h1 {
    margin: 25px 0;
}

.simulationTable,
.scrollableTableContainer {
    width: 90%;
    margin: auto;
    overflow-x: auto;
}

.simulationTable table,
.scrollableTableContainer table {
    width: 100%;
    text-align: center;
}

.tablesInfos section table {
    text-align: center;
}

.simulation-detail {
    text-align: center;
    h3 {
        text-align: center;
        color: var(--bgColor1D1);
    }
    b {
        text-transform: uppercase;
        color: var(--bgColor1);
    }
}

.simulations>section {
    width: 90%;
    margin: 0px auto 25px auto;
    display: flex;
}


.updateSimulationStatus {
    width: 90%;
    margin: auto;
}

.uss-statusTypes {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    span {
        background-color: #efefef;
        padding: 10px 15px;
        border-radius: 20px;
        cursor: pointer;
        &.isActive {
            background-color: var(--bgColor1);
            color: white;
        }
    }
}

.cl-filterAndExport{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    div{
        width: 60%;
    }
    button{
        width: 30%;
        background-color: var(--bgColor1);
        color: white;
        padding: 15px 0;
    }
}
.cl-filterAndExport 
.cl-filterAndExport 
.cl-filterAndExport select{
    width: 100%;
}

.clientsCount{
    font-size: 30px;
    b{
        color: var(--bgColor1);
    }
}

.simulationCountAndAction{
    display: flex;
    justify-content: space-between;
    align-items: center;
    b{
        color: var(--bgColor1);
    }
    section{
        display: flex;
        flex-direction: column;
        gap: 10px 0;
    }
}
@media screen and (max-width: 512px) {

    .simulationTable,
    .scrollableTableContainer {
        width: 95%;
    }

    .simulationFilterAndSearch>input,
    .simulationFilterAndSearch>div {
        width: 100%;
    }
}