.authPage {
 
}
.accueil {
  display: flex;
  .a-left{
    background-color: var(--bgColor1);
    width: 60%;
    clip-path: polygon(0 0, 100% 1%, 70% 100%, 0% 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
    img {
      margin: auto;
    }
  }
  .a-right{
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    form{
      width: 90%;
    }
  }
  h1 {
    color: white;
    text-align: center;
    text-transform: uppercase;
  }
}

/* .ab-illustration */
@media screen and (max-width: 758px) {
  .accueil {
    flex-direction: column;
    .a-left{
      width: 100%;
      clip-path: polygon(0 0, 100% 0, 100% 82%, 0% 100%);
      height: 30vh;
    }
    .a-right{
      width: 100%;
    }
  }
}