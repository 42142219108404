/* INPUT */
input:-webkit-autofill,
input:-moz-autofill,
input:-webkit-autofill:hover,
input:-moz-autofill:hover,
input:-webkit-autofill:focus,
input:-moz-autofill:focus,
input:-webkit-autofill:active,
input:-moz-autofill:active,
select:-webkit-autofill,
select:-moz-autofill,
select:-webkit-autofill:hover,
select:-moz-autofill:hover,
select:-webkit-autofill:focus,
select:-moz-autofill:focus,
select:-webkit-autofill:active,
select:-moz-autofill:active {}


.input-error-msg{
  margin-top: 5px;
  background-color: red;
  color: white;
  padding: 5px;
  border-radius: 5px;
}
input,
select,
textarea,
button {
  transition: all 0.5s;
}

input,
select,
textarea {
  padding: 20px 10px;
  border: solid 2px rgb(221, 217, 217);
}

input[type="radio"] {
  background-color: var(--cwd2);
}

input[type="file"] {
  display: none;
}

input:focus:not([type="radio"]),
textarea:focus,
select:focus {
  outline: none !important;
  border-width: 2px !important;
  border-color: var(--bgColor1D1) !important;
  background-color: white !important;
}

textarea {
  box-sizing: border-box;
}

input[type="number"] {
  cursor: pointer;
}

.formSegment {
  display: flex;
  flex-direction: column;
  margin: 5px auto;
}

.formSegment>label {
  padding: 5px 0;
  font-size: 18px;
  font-weight: bolder;
}

.fsFull {
  width: 100% !important;
}

.fsCenter {
  text-align: center;
}

.fsCenter div {
  justify-content: center;
}

.fsSemi {
  width: 45% !important;
}

.fs-radio_checkbox-row {
  display: flex;
  padding: 5px 0;
  gap: 10px;
  width: 100%;
}

.fs-radio_checkbox-row label input {
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  width: 25px !important;
  height: 25px !important;
  border-radius: 100%;
  padding: initial !important;
}

.fs-radio_checkbox-row label input:checked {
  background-color: var(--bgColor1);
}

.fs-radio_checkbox-row label {
  display: flex;
  align-items: center;
  color: grey;

}

.fs-radio_checkbox-column {
  display: flex;
  flex-direction: column;
}

.formSegment section {
  position: relative;
  display: flex;
  /* flex-wrap: wrap; */
  /* z-index: 655; */
  /* overflow-x: hidden; */
  /* overflow: hidden; */
}

.formSegment section input,
.formSegment section select,
.formSegment section textarea {
  width: 100%;
  overflow: hidden;
}

.formSegment section input[type="password"]:focus+.password-icon {
  /* background-color: var(--color3); */
}

/* Form Password field icon */
.password-icon,
.password-icon-visible {
  position: absolute;
  right: 0;
  height: 100%;
  padding: 0 15px;
  text-align: center;
  display: flex;
  align-items: center;
  top: 0px;
  bottom: 0px;
  cursor: pointer;
  transition: all 0.5s;
  border-radius: 10px;
  font-size: 14px;
}

.password-icon {
  transition: all 0.5s;
  color: grey;
}

.password-icon-visible {
  color: var(--bgColor1);
}



/*  */
.formSegmentFiles{
  .fsf-files{
      gap: 20px 0;
      article{
          background-color: #efefef;
          padding: 30px;
          border-radius: 10px;
          position: relative;
          div{
              position: absolute;
              right: 10px;
              bottom: -10px;
              gap: 0 10px;
              span{
                  cursor: pointer;
                  // display: block;
                  background-color: var(--bgColor1);
                  color: white;
                  border-radius: 10px;
                  padding: 8px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
              }
          }
      }
     
  }
  .fsf-newFile{
      gap: 10px 0;
      label{
          background-color: var(--bgColor1);
         margin-right: auto;
          padding: 10px;
          border-radius: 10px;
          color: white;
      }
  }
}

/*  */
.formSegmentTags {
  width: 100%;
  margin: 15px auto;
  display: flex;
  flex-direction: column;
}

.formSegmentTags span {
  cursor: pointer;
}

.formSegmentTags>span {
  margin: 10px auto 10px 0;
}

.tagReturnBtn {
  background-color: var(--cwd3);
  color: var(--color4);
  padding: 10px 15px;
  border-radius: 10px;
}

.tagAddBtn {
  background-color: var(--bgColor1);
  color: white;
  padding: 10px 15px;
  border-radius: 10px;
}

.formSegmentTags label {
  font-weight: bolder;
}

.fst-form {
  /* background-color: aqua; */
  width: 100%;
  margin: 0 auto 10px auto;
}

.fst-form input {
  width: 100%;
  margin: 10px auto;
}

.fst-form div {
  display: flex;
  gap: 10px;
}

.fst-list {
  /* background-color: aqua; */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin: 10px 0;
}

.fst-list p {
  background-color: var(--bgColor1D1);
  padding: 2px 5px;
  border-radius: 10px;
}

.fst-list p i {
  background-color: white;
  color: var(--bgColor1D1);
  border-radius: 100%;
  margin-left: 5px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  text-align: center;
  display: inline-block;
}

/*  */

/* Other auth */
.log-or-register {
  width: 100%;
  text-align: center;
}

.log-or-register p {
  color: grey;
}

.log-or-register a {
  color: var(--bgColor1);
}

@media screen and (max-width: 512px) {}