.profil {
  h1{
    max-width: 90%;
    margin: 25px auto;
    text-align: center;
    }
    &> form{
      width: 60%;
      margin: auto;
      padding: 0 25px;
      border-radius: 10px;
    }
}


@media screen and (max-width: 758px) {
  .profil > form{
    width: 90%;
  }
}
