@import "app.scss";

@import "pages/accueil.scss";
@import "pages/aide.scss";
@import "pages/avis.scss";
@import "pages/blog.scss";
@import "pages/client.scss";
@import "pages/dashboard.scss";
@import "pages/notification.scss";
@import "pages/profil.scss";
@import "pages/simulation.scss";
@import "pages/administration.scss";
@import "pages/emails-edition.scss";

@import "uikits/form.scss";
@import "uikits/modal.scss";
@import "uikits/nav.scss";

label,
span,
a {
  display: inline-block;
}

label,
a {
  cursor: pointer;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
}

img {
  object-fit: cover;
}

button {
  cursor: pointer;
}

/* table */
table {
  thead {
    th {
      padding: 10px;
      background-color: var(--bgColor1D2);
      color: grey;
      text-transform: uppercase;
      max-width: 100px;
    }
  }
  tbody {
    tr {
      td {
        padding: 10px;
        text-align: center;
        max-width: 100px;
        word-break: break-all;
        button {
          font-size: 15px;
        }
      }
      &:nth-child(even) {
        background-color: #d2e6fb40;
      }
    }
  }
}

.additionalFormOptions {
  gap: 0 8px;
  display: flex;
  align-items: center;
  input {
    transform: scale(1.3);
  }
}
/* The margin */
.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

/*  */
.flex {
  display: flex;
}

/*  */

.red {
  background-color: red;
}

.yellow {
  background-color: yellow;
}

.excelBtn {
  padding: 20px 40px;
  background-color: #128c7e;
  color: white;
}

.manyTableBtns {
  display: flex;
  gap: 10px 0;
  flex-direction: column;
}

.btnDisabled {
  background-color: #efefef !important;
  color: grey !important;
}
.pageNavigator {
  display: flex;
  align-items: center;
  gap: 0 15px;
  margin: 25px 0;
  font-size: 20px;
  button {
    background-color: var(--bgColor1);
    color: white;
    font-size: 20px;
  }
}

.Toastify__toast-container {
  z-index: 9999999999999999999999999999999;
}

.sun-editor {
  margin-top: 25px;
}

.reference {
  width: 90%;
  margin: auto;
  .r-list {
    gap: 50px;
    justify-content: space-evenly;
    & > section {
      background-color: #efefef;
      min-width: 40%;
      padding: 30px;
      border-radius: 25px;

      b {
        color: var(--bgColor1);
      }
    }
  }
}
