.client {
}


/* List client and preview */

.clients-list {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h1 {
    text-align: center;
  }
}


.d-c-search {
  width: 90%;
  margin: 15px auto;
  display: flex;
  input {
    width: 100%;
  }
}

.d-c-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  &>div {
    overflow: hidden;
    border-radius: 10px;
    width: 200px;
    display: flex;
    flex-direction: column;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
      rgba(17, 17, 26, 0.1) 0px 0px 8px;
    background-color: white;
    text-align: center;
    padding: 25px;
    img {
      width: 70px;
      height: 70px;
      margin: auto;
      object-fit: contain;
    }    
    main {
      margin: 10px 0;
      a {
        margin: 0 2px;
        background-color: var(--bgColor1);
        color: white;
        padding: 2px 5px;
        font-size: 14px;
        border-radius: 5px;
      }
      button {
        color: var(--bgColor1D1);
        padding: 3px;
        background-color: transparent;
        margin: 0 2px;
        &:first-child {
          color: var(--bgColor1);
        }
      }
    }
    p {
      font-size: 14px;
      color: grey;
      max-width: 90%;
      margin: auto;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:nth-child(2) {
        margin-top: 10px;
      }
      &:nth-child(3) {
        font-size: 12px;
      }
    }
  }
}


/* Client preview */
.cl-clientPreview {
  width: 300px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  img {
    width: 100%;
    height: 80px;
    width: 80px;
    border-radius: 100%;
    margin: auto;
  }
  b {
    color: var(--color4);
  }
  p {
    color: var(--color3);
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    a {
      border-radius: 10px;
      margin: 5px 0;
      padding: 2px 5px;
      color: white;
      background-color: var(--bgColor1);
    }
  }
}
/*  */


/* View and Manage client */

.current-client-name {
  text-align: center;
  padding: 0 0 20px 0;
  border-bottom: dashed 2px var(--bgColor1D1);
  color: grey;
  font-size: 25px;
}

/* Dash navigator */
.clientManagement {
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  // align-items: flex-start;
  // justify-content: space-evenly;
  flex-wrap: wrap;
  
  .cn-navResult {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    h2 {
      text-align: center;
      color: var(--bgColor1);
      margin: 10px auto;
    }
  }
  .cm-clientAndNavigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
 
    .cn-navItem {
      justify-content: space-evenly;
      width: 45%;
      display: flex;
      flex-wrap: wrap;
      label {
        min-width: 30%;
        background-color: white !important;
      }
    }

    .ccan-ClientInfosAndAction {
      width: 45%;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 30px 15px;
      flex-wrap: wrap;
      gap: 20px 0;
      border-radius: 20px;
      background-color: white;
      box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    }
  }
}

/* Client all info and actions */


.ccan-ClientInfosAndAction{
  .ccia-actions {
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin: auto;
    gap: 0 15px;
    width: 100%;
    svg{
      font-size: 30px;
    }
  }
  .ccia-infos {
    width: 100%;
    p {
      display: flex;
      color: grey;
      justify-content: space-between;
      b {
        color: black;
      }
    }
  }
  &>svg {
    display: none;
}
  
  
}



/*  */




.clientManagement label {
  input {
    display: none;
    &:checked+section {
      border-color: var(--bgColor1D1);
      color: var(--bgColor1D1);
    }
  }
  section {
    display: flex;
    justify-content: center;
    gap: 0 10px;
    border-radius: 5px;
    color: grey;
    padding: 20px 10px;
    transition: all 0.5s;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-bottom: solid 2px white;
    margin: 5px 0;
  }
}

/*  */

/* Transations */
.client-infosType {
  width: 100%;
}

.cit-liste {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px auto;
}



/*  */

/* Ressources */
.cr-salaire-trimestrielles {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin: 10px 0;
}

.client-ressources h3 {
  width: 100%;
  text-align: center;
  margin: 10px 0;
}

.cr-salaire-trimestrielles {
  p {
    color: var(--color4);
    background-color: var(--cwd2);
    padding: 20px 15px;
    border-radius: 10px;
    b {
      color: var(--bgColor1);
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
/*  */

/* Simulation list */
.cs-liste {
  width: 100%;
  div {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    background-color: #D2E6FB40;
    section {
      width: 49%;
      display: flex;
      flex-direction: column;
      padding: 2px 0;
      &:nth-child(1) {
        border-right: solid 1px var(--bgColor1D2);
      }
      
      &:nth-child(2) {
        border-left: solid 1px var(--bgColor1D2);
      }
    }
  }
  h3 {
    width: 90%;
    margin: 10px auto;
    text-align: center;
    color: var(--bgColor1D1);
  }
  p {
    color: var(--color4);
    padding: 5px 10px;
    word-break: break-all;
    b {
      color: black;
      text-transform: uppercase;
      word-break: break-all;
    }
  }
}

/*  */

/*  */
.client-info-saerch {
  width: 100%;
  margin: 0 auto;
}

.client-info-saerch input {
  width: 100%;
}


.client-info-addBtn {
  width: 100%;
  text-align: center;
  margin: 0 auto 10px auto;
}

.client-info-addBtn button {
  background-color: var(--bgColor1);
  color: white;
  padding: 10px 25px;
}

/*  */

/* Modal */
.clientInformationAddModal {
  /* width: 300px; */
}

.clientInformationAddModal input,
.clientInformationAddModal textarea {
  border: solid 2px var(--cwd3);
}

/*  */


.clientTable {
  width: 100%;
}


.ct-5 {
  width: 10%;
  text-align: center;
}

.ct-20 {
  width: 20%;
  text-align: center;
}

.ct-5 p,
.ct-20 p {
  width: 98%;
}

.ct-5 p,
.ct-20 p {
  margin: auto;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}



.modalViewClientInfos {
  margin: auto;
  text-align: center;
  b {
    color: var(--bgColor1D1);
    text-transform: uppercase;
  }
  p {
    margin: 10px 0;
    display: flex;
    flex-direction: column;
  }
  section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    b {
      width: 100%;
    }
    a {
      margin: 5px 0;
      background-color: var(--cwd2);
      padding: 4px 8px;
      color: var(--color4);
    }
  }
}

/* Create client */
.createClient {
  width: 90%;
  margin: auto;
}

/*  */

@media screen and (max-width: 512px) {
  .clientManagement {
    width: 98%;
  }

  .clientManagement label section {
    font-size: 12px;
  }

  /* Ressources */
  .cr-salaire-trimestrielles p {
    padding: 10px 8px;
    margin: 10px 0;
  }

  .cr-salaire-trimestrielles p b {
    font-size: 15px;
    margin-right: 5px;
  }

  /*  */

  /* Alerte,Transaction.. */
  .ct-liste>div,
  .ca-liste>div,
  .cn-liste>div,
  .cd-liste>div {
    padding: 10px 5px;
    max-width: 100%;
  }


  .cs-liste div section {
    /* font-size: 14px; */
  }

  .cs-liste div p {
    font-size: 12px;
  }

  .cs-liste div p b {
    font-size: 14px;
  }

  /*  */




}


@media screen and (max-width: 972px) {
  .cm-clientAndNavigation {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    section,div{
      width: 100% !important;
    }
  }
}


@media screen and (max-width: 768px) {
  .clientManagement {
    /* background-color: grey; */
    justify-content: center;
  }

  

  .cn-navResult {
    width: 100%;
  }

  /* .ccia-infos {
    min-width: 95%;
  } */

  .ccan-ClientInfosAndAction,
  .cn-navItem {
    width: 90%;
  }
}




@media screen and (min-width: 768px) and (max-width: 992px) {
  .clients-list {
    width: 90%;
  }

  .d-c-list>div {
    width: 30%;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .clients-list {
    width: 90%;
  }

  .d-c-list>div {
    width: 20%;
    margin: 5px;
  }
}

@media screen and (max-width: 1200px) {}