.notification {
    h1 {
        max-width: 90%;
        margin: auto;
        text-align: center;
    }
    &>section {
        width: 90%;
        margin: auto;
    }
}

.nts-list{
    display: flex;
    flex-wrap: wrap;
    gap: 25px;
    justify-content: center;
    .ns-notif {
        width: 45%;
        background-color: white;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 0.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset;
        padding: 10px 15px;
        margin: 10px 0;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        section{
            display: flex;
            flex-direction: column;
            gap: 25px 0;
            b{
                color: var(--bgColor1);
            }
        }
        a{
            color: white;
        }
    }
}

@media screen and (max-width: 758px) {
    .nts-list{

        .ns-notif {
            width: 100%;
        }
    }
  }