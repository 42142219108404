.avis {
    padding: 100Px 0;
    width: 90%;
    margin: auto;
    .avisTrustpilot {
        padding: 100px 0;
        display: flex;
        flex-direction: column;
    }
}



.av-counterManager,
.av-reviewsManager {
    width: 90%;
    margin: 25px auto;
    &>button {
        background-color: var(--bgColor1);
        color: white;
        text-align: center;
        padding: 10px 15px;
    }
    &.av-reviewsManager>section {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        margin: 25px auto;
        h2 {
            width: 100%;
        }
    }
}

.reviewBox {
    position: relative;
    width: 300px;
    box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
    border-radius: 20px;
    display: flex;
    padding: 30px;
    flex-direction: column;

    gap: 10px 0;
    p {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }
}

.reviewBoxActions {
    display: flex;
    gap: 0 20px;
}