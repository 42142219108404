:root {
  --bgColor1: #023A77;
  --bgColor1D1: #1AA8E9;
  --bgColor1D2: #D2E6FB;

  --color3: #d5271c;
  --color4: #455a64;
  --color5: #263238;
  --colorBgw: #f1f1f1;

  --pageWidth: 250px;

  --cwd1: #f2f2f2;
  --cwd2: #e6e6e6;
  --cwd3: #d9d9d9;
  --cwd4: #cccccc;

  --cbd1: #0d0d0d;
  --cbd2: #1a1a1a;
  --cbd3: #262626;
  --cbd4: #333333;

  --googleColor: #c22322;
  --whatsappColor: #46c756;
  --telColor: #128c7e;

  --ob2: rgba(0, 0, 0, 0.2);
  --ob4: rgba(0, 0, 0, 0.4);
  --ob6: rgba(0, 0, 0, 0.6);
  --ob8: rgba(0, 0, 0, 0.8);
  --ob10: rgba(0, 0, 0, 1);

  --ow2: rgba(255, 255, 255, 0.2);
  --ow4: rgba(255, 255, 255, 0.4);
  --ow6: rgba(255, 255, 255, 0.6);
  --ow8: rgba(255, 255, 255, 0.8);
  --ow10: rgba(255, 255, 255, 1);

  --errorColor: #b30000;
  --successColor: #38d368;
}


.pageLoader {
  min-height: 90vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@font-face {
  font-family: "Poppins-Medium";
  src: url("../fonts/Poppins-Medium.ttf");
}

@font-face {
  font-family: "Poppins-Light";
  src: url("../fonts/Poppins-Light.ttf");
}

@font-face {
  font-family: "Poppins-SemiBold";
  src: url("../fonts/Poppins-SemiBold.ttf");
}

iframe {
  pointer-events: none;
}

html,
body {
  overflow-x: hidden !important;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins-Medium";
}

button {
  border-radius: 10px;
  padding: 15px 30px;
  border: 0;
  background-color: var(--bgColor1);
  color: white;
}

input,
select {
  border-radius: 10px;
}

.flex {
  display: flex;
}

.f-wrap {
  flex-wrap: wrap;
}

.center-x {
  justify-content: center;
}

.f-center-xy {

  justify-content: center;
}

.ai-center {
  align-items: center;
}

* {
  box-sizing: border-box !important;
}

div.ql-video {
  min-width: 90%;
  margin: auto;
  text-align: center;
  aspect-ratio: 1/0.5;
}

main {
  margin-left: auto;
  min-height: 100vh;
}

/*  */
.pageTitle {
  max-width: 90%;
  margin: 0 auto 25px auto;
  text-align: center;
  h1 {
    padding: 15px 25px;
    border-radius: 10px;
    background-color: var(--bgColor1D2);
    color: var(--bgColor1);
    display: inline-block;
  }
}
/*  */

/*  */
.searchAndAddNewItemSection {
  width: 100%;
  margin: 25px auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  &>section {
    width: 70%;
    input {
      width: 100%;
    }
  }
}

.searchAndAddNewItemSection>a,
.searchAndAddNewItemSection>button {
  width: 25%;
  background-color: var(--bgColor1);
  border-radius: 10px;
  color: white;
  text-align: center;
  padding: 15px 0;
}
/*  */

/*  */
.dataBox {
  position: relative;
  .dataBoxActions {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--bgColor1D1);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0 20px;
    padding: 5px 10px;
    svg {
      cursor: pointer;
    }
  }
}

.addOrUpdateStatus {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.badStatus {
  color: red;
}

.goodStatus {
  color: green;
}

.fullScreenLoader{
  background-color: var(--bgColor1);
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80px;
  min-height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  
  top: 0;
  width: 100%;
  z-index: 9999999999999999;
}

.sectionLoader{
  font-size: 50px;
  color: var(--bgColor1);
  text-align: center;
  padding: 75px 0;
  width: 100%;
  margin: auto;

}
@media screen and (max-width: 512px) {
  .searchAndAddNewItemSection {
    width: 90%;
    gap: 10px 0;
  }

  .searchAndAddNewItemSection>section,
  .searchAndAddNewItemSection>a,
  .searchAndAddNewItemSection>button {
    width: 100%;
  }

}

