.navDash {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    padding: 10px 30px;
    background-color: white;
    border-bottom: solid 1px #efefef;
    color: var(--bgColor1);
    transition: all .5s;
    svg {
        cursor: pointer;
    }
    margin-left: auto;
    z-index: 9999;
}

.nd-logo {
  border-radius: 5px;
  background-color: white;
  padding: 0 15px;
  img {
    width: 80px;
  }
}

.nav-auth {
  margin-left: auto;
  a {
    color: var(--bgColor1);
    border: solid 2px var(--bgColor1);
    padding: 10px 25px;
    border-radius: 10px;
  }
}


.nd-logout {
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    font-size: 30px;
  }
}

  .navSideBar {
    z-index: 10000;
    transition: all .5s;
    width: 250px;
    position: fixed;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    opacity: 1;
    left: 0;
    bottom: 0;
    padding: 50px 0;
    background-color: var(--bgColor1);
    box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
    &~div.authedContainer {
      padding: 85px 0;
      margin-left: auto;
      width: calc(100% - var(--pageWidth));
      transition: all .5s;
    }
    &~.navDash{
      width: calc(100% - 250px) !important;
      left: 250px !important;
    }

    &.hideNavSideBar {
      left: -250px !important;
      &~.authedContainer{
        margin: auto;
        width: 100% !important;
      }
      &~.navDash {
        width: 100% !important;
        left: 0 !important;
      }
    }
   
  }

  
  
  
  .ns-links {
    width: 100%;
    margin: 0 auto auto auto;
    display: flex;
    flex-direction: column;
    a,div>span {
    width: 100%;
    color: var(--bgColor1D2);
    gap: 0 10px;
    padding: 10px 0 10px 30px;
    transition: all .5s;
    cursor: pointer;
  }
  a:hover,div>span:hover{
    color: white;
  }
  }
  

  
  .siseBarActiveLink {
    width: 90% !important;
    border-radius: 0 5px 5px 0px;
    background-color: white;
    color:var(--bgColor1) !important;
  }


  @media screen and (max-width: 758px) {
    .navSideBar {
      
      &~div.authedContainer {
        margin: auto;
        width: 100%;
        transform: translateX(250px);
      }
      // &~.navDash{
      //   width: calc(100% - 250px) !important;
      //   left: 250px !important;
      // }
  
      &.hideNavSideBar {
        &~.authedContainer{
          margin: auto;
          transform: initial;
        }
        // &~.navDash {
        //   width: 100% !important;
        //   left: 0 !important;
        // }
      }
     
    }
  }