.blogPart {
    width: 90%;
    margin: auto;
}

.bpc-categoryList>section {
    display: flex;
    gap: 0 20px;
    flex-wrap: wrap;
}

.blogCategoryCard {
    border: solid 2px #efefef;
    border-radius: 5px;
    width: 150px;
    padding: 15px 10px;
    text-align: center;
    p {
        display: flex;
        justify-content: center;
        gap: 0 10px;
        span {
            cursor: pointer;
        }
    }
}
/*  */
.bp-view{
    display: flex;
    flex-direction: column;
    justify-content: center;
    &>h1 {
        text-align: center;
    }
    &>section {
        padding: 20px 25px;
        text-align: center;
    }
}

.bpv-actions {
    display: flex;
    justify-content: center;
    gap: 0 20px;
}

.bpv-titles {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 20px;
    &>div:last-child {
        width: 100%;
        text-align: center;
        img {
            width: 100%;
            aspect-ratio: 1/0.5;
        }
    }
}

.bpv-content {
    display: flex;
    flex-direction: column;
}

.jodit-react-container ul,
.editorContentBox ul {
    display: block !important;
    list-style-type: initial !important;
    -webkit-margin-before: 1em;
    -webkit-margin-after: 1em;
    -webkit-margin-start: 0px;
    -webkit-margin-end: 0px;
    -webkit-padding-start: 40px;
}

/* Comment  */


.articleCommentSection {
    width: 100%;
    margin: auto;
}


.accCommentList,
.accCommentResponsesList {
    display: flex;
    flex-direction: column;
    gap: 20px 0;
    /* background-color: gold; */
}

.commentSection {
    display: flex;
    gap: 0 20px;
    &>span {
        width: 50px;
        height: 50px;
        border-radius: 100%;
        line-height: 50px;
        color: grey;
        text-align: center;
        background-color: #efefef; 
    }
    &>div {
        max-width: 80%;
    }
}

.cs-actions {
    display: flex;
    gap: 0 5px;
}

.cs-actions button:first-child {
    background-color: transparent;
    border: solid 2px grey;
    color: grey;
}

.cs-actions button:last-child {
    background-color: transparent;
    color: grey;
}

.commentForm,
.accCommentResponsesList {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
    transition: all 500ms ease-in;
}

.accCommentResponsesListVisible {
    max-height: 600px;
    overflow: initial;
    opacity: 1;
    /* padding: 25px 0; */
}

.commentFormVisible {
    padding: 25px 0;
    max-height: 600px;
    overflow: initial;
    opacity: 1;
}

/*  */