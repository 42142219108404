.aides {
    h1 {
        width: 100%;
        margin: 25px auto;
        text-align: center;
    }
    button {
        margin: auto;
        text-align: center;
        background-color: var(--bgColor1);
        color: white;
        padding: 10px 20px;
    }
}

.aides-liste,
.aides-new {
    width: 90%;
    margin: auto;
}

.aides-new {
    text-align: center;
}

.aides-liste {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    article {
        background-color: var(--cwd1);
        padding: 15px 20px;
        text-align: center;
        margin: 10px 0;
        width: 15%;
        span {
            display: flex;
            justify-content: space-evenly;
            i {
                cursor: pointer;
                font-size: 25px;
            }
        }
    }
} 

@media screen and (max-width: 512px) {
    .aides-liste article {
        width: 45%;
    }
}