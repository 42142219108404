.viewIcon {
    color: var(--bgColor1);
}

.updateIcon {
    color: var(--succesColor);
}

.deleteIcon {
    color: var(--errorColor);
}


.administration {
    h1 {
        max-width: 90%;
        text-align: center;
        margin: 25px auto;
    }
    .administration-add {
        width: 90%;
        margin: auto;
        text-align: center;
        button {
            background-color: var(--bgColor1);
            color: white;
            padding: 10px 15px;
        }
    }

    .administration-search {
        width: 80%;
        margin: 25px auto;
        text-align: center; input {
            width: 100%;
            border: solid 2px var(--cwd3);
            border-radius: 10px;
        }
    }


    .administration-accounts {
        width: 80%;
        margin: 25px auto;
        table {
            width: 100%;
            thead {
                background-color: var(--cwd1);
            }
        }
        th {
            padding: 15px 0;
        }
        td {
            padding: 10px 0;
            border: solid 2px var(--cwd2);
            text-align: center;
            color: var(--color3);
        }
        i {
            font-size: 20px;
            margin: 0 5px;
            cursor: pointer;
        }
    }
    
}

.administration-addForm {
    width: 500px;
    input {
        background-color: var(--cwd2);
    }
}

@media screen and (max-width: 512px) {
    .administration-accounts {
        width: 95%;
    }
}

@media screen and (min-width: 512px) and (max-width: 768px) {
    .administration-accounts {
        width: 90%;
    }
}