.emails-edition{
    width: 90%;
    margin: 50px auto;
    .ee-emailsList{
        margin: 50px 0;
        gap: 20px;
        article{
            background-color: #efefef;
            padding: 40px;
            border-radius: 20px;
            button{
                background-color: var(--bgColor1);
                color: white;
            }
        }
    }
}