.notFoundTxt {
  text-align: center;
}


.dashboard-stats,
.dashboard-lastClients {
  margin: auto;
  width: 90%;
}

.dashboard-stats {
  display: flex;
  gap: 20px 0;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-around;
}

.dashStatsBox {
  display: flex;
  // flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0 10px;
  padding: 15px 30px;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  white-space: nowrap;
  b {
    color: var(--bgColor1);
  }
  span{
    white-space: nowrap;
  }
  
}

.dashboard-stats h4 {
  margin-bottom: 20px;
  color: var(--bgColor1);
  font-size: 30px;
}

.ds-left {
  width: 100%;
}


.ds-right {
  border-radius: 10px;
  padding: 20px;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
}


.ds-statsList {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* justify-content: space-between; */
}

.dashboard-lastClients{
  margin: 50px auto;
  width: 90%;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
  &>div {
    overflow-x: auto;
    table {
      width: 100%;
    }
  }
}


/*  */
@media screen and (max-width: 758px) {

  .ds-left,
  .ds-right {
    width: 100%;
  }

  .dashStatsBox {
    width: 100% !important;
  }

}